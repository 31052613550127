<template>
  <div role="main" class="container">
    <article>
      <div class="row my-2 text-right">
        <div class="col">
          <a href="#" @click.prevent="$router.go(-1)">
            <img src="@/assets/IC_Arrow_Left_pink_secondary.svg" alt="back" />
            <span class="back-link">{{ $t("components.actions.back") }}</span>
          </a>
        </div>
      </div>

      <card-data-component :tabs="tabs">
        <template #data>
          <div class="profile data-load" :class="{ 'sk-loading': isLoading }">
            <sk-spinner v-if="isLoading"></sk-spinner>
            <article>
              <h5>{{ $t("pages.profile.tabs.personal_data.general") }}</h5>
              <form v-if="userData">
                <div class="row">
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.employee_code')"
                      :value="userData.employeeCode"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.name')"
                      :value="userData.name"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.first_family_name')"
                      :value="userData.firstFamilyName"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.second_family_name')"
                      :value="userData.secondFamilyName"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.full_name')"
                      :value="userData.fullName"
                    />
                  </div>
                  <div class="col-md-6">
                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.company_phone')"
                      :value="userData.phoneNo2"
                    />

                    <suc-text-form-show-field
                      :title="$t('pages.profile.tabs.personal_data.company_email')"
                      :value="userData.companyEmail"
                    />
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col text-right">
                    <vue-button
                      class="btn-primary"
                      :title="$t('components.actions.edit')"
                      @click="showGeneralEditForm"
                    ></vue-button>
                  </div>
                </div> -->
              </form>
            </article>
          </div>
        </template>
      </card-data-component>
    </article>
  </div>
</template>

<script>
import CardDataComponent, { Tab } from "@/pages/components/card-data";
import api from "@/api/user";
// import { VueButton } from "@/components/form";

import { SucTextFormShowField } from "@/components/form";
import SkSpinner from "@/components/SkSpinner";

import { WhoAmIMixin } from "@/components/mixins";

export default {
  mixins: [WhoAmIMixin],
  components: {
    // VueButton,
    CardDataComponent,
    SucTextFormShowField,
    SkSpinner,
  },
  layout: "MainLayout",
  middleware: ["auth", "notification"],
  data() {
    return {
      tabs: [new Tab({ name: this.$t("pages.profile.tabs.personal_data.title") })],
      isLoading: false,
      userData: null,
    };
  },
  computed: {
    employeeCode() {
      return this.$route.params.code;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;

      const { data } = await api.whoisUser(this.employeeCode);
      this.userData = data;

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  & article {
    margin-top: 12px;

    & > h5 {
      border-bottom: 2px solid $color-primary;
      padding-bottom: 5px;
      margin-bottom: 12px;
    }
  }

  form label,
  .label-description {
    color: $color-gray;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19.6px;
    text-transform: uppercase;
  }

  form .description {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    height: 22.4px;
  }

  form .label-description {
    margin: 0;
  }

  form input {
    max-width: 300px;
  }
}
</style>
