import api from "../../api/user";
import { mapActions } from "vuex";

export default {
  created() {
    this.whoAmI();
  },
  methods: {
    ...mapActions("auth", ["saveData"]),
    async whoAmI() {
      const { data } = await api.whoAmI();
      this.saveData(data);
    },
  },
};
